// src/components/layouts/BottomNav.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link'; // Import HashLink for smooth scrolling
import { HomeIcon, InformationCircleIcon, ShoppingBagIcon, PhoneIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const icons = {
    '#home': <HomeIcon className="w-6 h-6" />,
    '#about-us': <InformationCircleIcon className="w-6 h-6" />,
    '#plans': <ShoppingBagIcon className="w-6 h-6" />,
    '#contact-us': <PhoneIcon className="w-6 h-6" />,
    '/partner': <UserGroupIcon className="w-6 h-6" />,
};

const BottomNav = ({ navLinks }) => {
    const location = useLocation();

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white shadow-md py-2 flex justify-around md:hidden">
            {navLinks.map((link) => {
                const isActive = location.hash === link.path || location.pathname === link.path;
                return (
                    <div key={link.path} className="flex flex-col items-center">
                        <Link
                            to={link.path.includes('#') ? link.path : link.path}
                            className={`flex flex-col items-center ${isActive ? 'text-blue-400' : ''}`}
                        >
                            {icons[link.path]}
                            <span className="text-xs">{link.label}</span>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
};

export default BottomNav;
