// src/components/layouts/Layout.jsx
import React from 'react';
// import Header from './Header';
import Footer from './Footer';
// import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import useConfig from '../../hooks/useConfig';
import BottomNav from './BottomNav';

const Layout = () => {
  const config = useConfig();

  return (
    <div className="flex flex-col min-h-screen">
      {/* <Header /> */}
      <div className="flex flex-grow flex-col md:flex-row">
        {/* <Sidebar /> */}
        <main className="flex-grow p-4">
          <Outlet />
        </main>
      </div>
      {config && <BottomNav navLinks={config.navLinks} />}
      <Footer />
    </div>
  );
};

export default Layout;
