import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import Layout from './components/layouts/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import store from './store';
import Cookies from 'js-cookie'; // Assuming js-cookie is installed

const HomePage = lazy(() => import('./pages/HomePage'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccessPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const PartnerPage = lazy(() => import('./pages/PartnerPage'));
const ProductsPage = lazy(() => import('./pages/ProductsPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicyPage'));
const DisclaimerPage = lazy(() => import('./pages/DisclaimerPage'));
const TermsofUse = lazy(() => import('./pages/TermsOfUse'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditionsPage'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const SignInPage = lazy(() => import('./pages/SignInPage'));
const SignOutpage = lazy(() => import('./pages/SignOutpage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const AnalyticsPage = lazy(() => import('./pages/AnalyticsPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage')); // Import ProfilePage
const SettingsPage = lazy(() => import('./pages/SettingsPage')); // Import ProfilePage
const BrokerPage = lazy(() => import('./pages/BrokerPage')); // Import ProfilePage
const PlanPage = lazy(() => import('./pages/PlanPage')); // Import ProfilePage
const HelpPage = lazy(() => import('./pages/HelpPage')); // Import ProfilePage
const NotFoundPage = lazy(() => import('./pages/NotFoundPage')); // Import ProfilePage

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accessToken = localStorage.getItem('access_token');
  console.log("access token", accessToken)
  return isAuthenticated || accessToken ? element : <Navigate to="/sign-in" />;
};

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="payment" element={<PaymentPage />} />
                <Route path="payment-success" element={<PaymentSuccess />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/disclaimer" element={<DisclaimerPage />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/terms-of-use" element={<TermsofUse />} />
                <Route path="/partner" element={<PartnerPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
              </Route>
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} />
              <Route path="/analytics" element={<ProtectedRoute element={<AnalyticsPage />} />} />
              <Route path="/profile" element={<ProtectedRoute element={<ProfilePage />} />} />
              <Route path="/settings" element={<ProtectedRoute element={<SettingsPage />} />} />
              <Route path="/broker" element={<ProtectedRoute element={<BrokerPage />} />} />
              <Route path="/plan" element={<ProtectedRoute element={<PlanPage />} />} />
              <Route path="/help" element={<ProtectedRoute element={<HelpPage />} />} />
              <Route path="/sign-out" element={<ProtectedRoute element={<SignOutpage />} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
};

export default App;
